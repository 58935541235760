<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-navbar-nav class="nav align-items-center mr-auto">
      <b-navbar-brand @click="$router.push({ name: 'home'}).catch(()=>{})">
      <div class="brandImageTextBox">
        <img :src="require('@/assets/images/logo/logo.png')"  style="height:35px;" alt="Kitten">
   
      <p class="pl-0 position-relative navText" v-if="!hideMobile()">
        <span class="pl-0 pr-0 ml-0 mr-0 navTextLft">
         elite
        </span>emperors
    </p>
      </div>
    </b-navbar-brand> 
    </b-navbar-nav>

    <b-navbar-nav  class = "nav align-items-center abs-center-x" :hidden="hideMobile()">
    <b-nav-item  class="text-white px-1 navSmallText" @click="send(1)"><span class="text-white">Explore</span></b-nav-item>
    <b-nav-item  class="navSmallText" @click="send(2)"><span class="text-white">Store</span></b-nav-item>

    </b-navbar-nav>
    <!-- Left -->
    <b-navbar-nav class="nav align-items-center  ml-auto brandNavBox" v-if="user">
      <b-badge right class="text-success badgeCrowns badgeCrownText" v-if="((exp.crowns>0 || exp.goblets>0) && !hideMobile())"
        style="margin-right:10px;">
         <span><span v-if="exp.crowns>0"><b-img :src="require('@/assets/images/illustration/crown.svg')" fluid style="width: 18px;margin-top:-4px;"/>
           {{numberWithCommas(exp.crowns)}}</span><span class="border-right" v-if="((exp.goblets>0 && exp.crowns>0) && !hideMobile())">&nbsp</span><span style="margin-left:7px;" v-if="exp.goblets>0"><b-img :src="require('@/assets/images/illustration/goblet.svg')" fluid style="width: 18px;margin-top:-4px;"/> {{numberWithCommas(exp.goblets)}}
        </span></span></b-badge>
        <notification-dropdown  right :hidden="hideMobile()"/>
         <b-nav-item  class="px-1 navSmallText" @click="send(1)" :hidden="(!hideMobile() && user)">
          <span class="text-white">Explore</span></b-nav-item>
    <b-nav-item  class="navSmallText" @click="send(2)" :hidden="(!hideMobile() && user)">
      <span class="text-white">Store</span></b-nav-item>
    <b-avatar
            size="42"
            variant="light-primary" 
            :src="avatarIMG"
            class="badge-minimal"
            v-b-toggle.sidebar-right
            :hidden="(!hideMobile())"
          />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="mr-1 pr-1"
        id="iddrop"
        :hidden="(hideMobile())"
      >
        <template #button-content>
          <b-avatar
            size="42"
            variant="light-primary" 
            :src="avatarIMG"
            class="badge-minimal"
          />
        </template>
          <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'profile'}).catch(()=>{})">
          <span>Profile</span>
        </b-dropdown-item>
           <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'createquest'}).catch(()=>{})"
            v-if="user.rol=='Manager'">
          <span>{{$t("createquest")}}</span>
        </b-dropdown-item>
         <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'createitemquest' }).catch(()=>{})"
         v-if="user.rol=='Manager'">
          <span>{{$t("createitemquest")}}</span>
        </b-dropdown-item>
         <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'createcomments' }).catch(()=>{})"
          v-if="user.rol=='Manager'">
          <span>{{$t("createcomments")}}</span>
        </b-dropdown-item>
          <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'addstock' }).catch(()=>{})"
          v-if="user.rol=='Manager'">
          <span>{{$t("addstock")}}</span>
        </b-dropdown-item>
          <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'stockmisterybox' }).catch(()=>{})"
          v-if="user.rol=='Manager'">
          <span>Mystery Box</span>
        </b-dropdown-item>
         <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'mysteryboxgained' }).catch(()=>{})"
          v-if="user.rol=='Manager'">
          <span>Mystery Box List</span>
        </b-dropdown-item>
         <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'validateimages' }).catch(()=>{})"
          v-if="user.rol=='Manager'">
          <span>{{$t("validateimages")}}</span>
        </b-dropdown-item>
          <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'validatebuy' }).catch(()=>{})"
          v-if="user.rol=='Manager'">
          <span>{{$t("validatebuy")}}</span>
        </b-dropdown-item>
         <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'deletequest'}).catch(()=>{})"
            v-if="user.rol=='Manager'">
          <span>{{$t("deletequest")}}</span>
        </b-dropdown-item>
         <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'deleteitem'}).catch(()=>{})"
            v-if="user.rol=='Manager'">
          <span>{{$t("deleteitem")}}</span>
        </b-dropdown-item>
          <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'topweek'}).catch(()=>{})"
            v-if="user.rol=='Manager'">
          <span>Top Usuarios</span>
        </b-dropdown-item>
            <b-dropdown-item link-class="d-flex align-items-center"  @click="modalbug=true">
          <span>Feedback</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'feedbackdetails'}).catch(()=>{})"
            v-if="user.rol=='Manager'">
          <span>{{$t("feedback")}}</span>
        </b-dropdown-item>
         <b-dropdown-item link-class="d-flex align-items-center"  @click="$router.push({ name: 'faq'}).catch(()=>{})">
          <span>{{$t("faq")}}</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{$t("logout")}}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
   
    </b-navbar-nav>
    <!-- Left Login -->
    <b-navbar-nav class="nav align-items-center ml-auto brandNavBox" v-if="!user">
    <!-- <div 
    right
    > -->
    <b-button pill class="btnColor" @click="openmodal=true">Connect Discord</b-button>

  <!-- </div> -->
    </b-navbar-nav>
    <b-modal
     v-model="openmodal"
     hide-header
     hide-footer
     centered
     > 
    <b-row class="mt-1 mb-1">
      <b-col cols="12">
        <div class="text-center">
          <b-img
          src="/logo.png"
      rounded="circle"
      alt="Circle image"
      class="d-inline-block mr-1 mb-1"
    />
    </div>
      </b-col>
      <b-col cols="12">
          <div class="text-center">
        <h2 class="font-weight-bolder" style="background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
        -webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;
        text-fill-color: transparent;">Welcome EliteRewards</h2>
          </div>
      </b-col>
      <b-col cols="12">
        <div class="text-center mt1">
        <span>Connect with your discord and start earning rewards</span>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="text-center mt-2">
        <b-button
        block
        pill
        :href="validationForm()"
        class="backgroundBtn"
        style="padding:18px 80px;"
        >
          Connect Discord
        </b-button>
        </div>
      </b-col>
    </b-row> 
    </b-modal>
    <b-modal
    v-model="modalbug"
    hide-footer
    centered
    ref="rbug">
    <feedback/>
    </b-modal>
    <div v-if="user">
      <SideBarMobile id="sidebar-right" :exp="exp" :avatarIMG="avatarIMG" :user="user"/>

    </div>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BModal,
  BForm,BFormInput,BFormGroup,BBadge,BNavbarBrand,BButton,BNavItem,BImg,BCol,BRow,VBToggle
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from "./NotificationDropdown.vue"
import NotificationDrop from "./NotificationDrop.vue"
import Locale from "./Locale.vue"
import Feedback from "./Feedback.vue"
import SideBarMobile from "./SideBarMobile.vue"
import SellerService from '@/services/SellerService';
const user = JSON.parse(localStorage.getItem("auth"));
import queryString from "@/utils/queryString";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,BModal,
    BNavItem,
    NotificationDropdown,BForm,BFormInput,BFormGroup,
    NotificationDrop,
    BButton,
    BImg,
    BCol,
    BRow,
    DarkToggler,BBadge,Locale,Feedback,BNavbarBrand,BModal,SideBarMobile
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data:function(){
    return{
      user:user,
        datos:true,
        exp:0,
        openmodal:false,
        interval:null,
        modalbug:false
    }
  },
 
  directives: {
      'b-toggle': VBToggle,
    },
  computed:{
    name:function()
    {
      return this.user.username;
    },
    role: function(){
      
      if(this.user)
      {
        return this.user.rol;
      }
      return ""
        
    },
    avatarIMG:function(){
      if (this.user.avatar!=null) {
         if (this.user.avatar.substring(0,2) === 'a_') {
          return  "https://cdn.discordapp.com/avatars/"+ this.user.discordId+"/"+this.user.avatar+".gif"  
          }

          return  "https://cdn.discordapp.com/avatars/"+ this.user.discordId+"/"+this.user.avatar+".png"  
      }
    },
  },
  mounted(){
      this.getExpbyUser()
      this.$root.$on("modalbug",(bug)=>{this.modalbug=bug})
  },
  beforeDestroy () {
  clearInterval(this.interval)
},
  methods:{
    send:function(val){
      if(val==1 && this.user){
        this.$router.push({ name: 'home'}).catch(()=>{});
      }
      else if(val==2 && this.user){
        this.$router.push({ name: 'store'}).catch(()=>{});
      }
      else{
        this.$swal({
          title: "",
          text: "Please connect your discord",
          icon: "error",
           allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
    },
    getExpbyUser:async function(){
      const ex=(await SellerService.getExpbyUser(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.exp=ex
    },
       numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    logout: function() {
      setTimeout(() => {
        this.$store.dispatch("user/removeUser");
        this.$router.go({name:"login"});

        this.$root.auth = null;
      }, 10);
    },
    hideMobile:function(){
       
      return !['xl','lg'].includes(this.$store.getters['app/currentBreakPoint']);
    },
    validationForm: function() {
    // if(window.location.href!="https://deltacash.tk/login")
    //  { 
      const loginParams = {
        client_id: "1041092168573534268",
        redirect_uri: window.location.href+"/discord",
        response_type: "token",
        scope: "identify guilds"
      };
      return `https://discordapp.com/api/oauth2/authorize${queryString(loginParams)}`;
     }
  }
}
</script>

<style>
.btnColor{
  background: linear-gradient(87.2deg, #a488ffcc 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
padding: 10px 32px;
gap: 8px;
border: transparent;
right: 5.29%;
top: 18.75%;
bottom: 18.75%;
width: 188px;
height: 40px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: center;
letter-spacing: 0.015em;
}
.navSmallText{ 
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 32px;
letter-spacing: 0.015em;
color: #FFFFFF;
}
.brandImageTextBox{
  display: flex;
  position: absolute;
flex-direction: row;
justify-content: center;
align-items: center;
left: 10%;
top: 25%;
bottom: 25%;
}
.brandNavBox{
  display: flex;
  position: absolute;
flex-direction: row;
justify-content: center;
align-items: center;
right: 10%;
}
.navText{
  display: flex;
 
top: 35%;
bottom: 25%;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.015em;
}

.navTextLft{
  color: #8CE0B8;
  right: -0.55vw; 
}
.brandNavImg{
  position: absolute;
left: 5.29%;
right: 92.6%;
top: 25%;
bottom: 25%;
}
.dark-layout .header-navbar{
  background: #000000;
}
.badgeCrownText{
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px; 
text-align: right;
letter-spacing: 0.015em;
}
.badgeCrowns{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;
gap: 9px;
left: 78.84%;
right: 12.73%;
top: 20.31%;
bottom: 17.19%;
background: #223433;
border-radius: 8px;

}
@media (min-width: 768px) {
    .abs-center-x {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
