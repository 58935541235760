<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        :class="{ 'bounce': istoday}"
        icon="CodesandboxIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Team Rocks (Goals)
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{notifications.length?notifications.length+' New':""}}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area" v-if="notifications"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.title"
      >
      <p v-show="false"></p>
        <b-media>
          <template #aside>
            <b-avatar
            rounded
            variant="light-primary"
            size="32"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.date }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import HOBService from "@/services/HOBService.js";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data:function()
  {
    
    return {
      notifications:[],
    istoday:false}

  },
  methods:{
    getRocks: async function () {
      const ro = (
        await HOBService.getGoalRocks(
          JSON.parse(localStorage.getItem("auth")).username
        )
      ).data;
      this.notifications = ro.goals;
      this.istoday=ro.istoday
    }
  },
 async mounted(){
     await this.getRocks()
  },
  setup() {
    

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.bounce {
   width: 21px;
  height: 21px;
  border-radius: 50%;
  animation: bounce 1s infinite,glow 1s infinite alternate;
  
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #aef4af;
  }
  to {
    box-shadow: 0 0 10px 10px #aef4af;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    transform: translate3d(0, -20px, 0);
  }
  70% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
</style>
